import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InputComponent} from './input/input.component';
import {HeaderComponent} from './header/header.component';
import {SpinnerComponent} from './spinner/spinner.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    HeaderComponent,
    InputComponent,
    SpinnerComponent
  ],
  exports: [
    HeaderComponent,
    InputComponent,
    SpinnerComponent
  ]
})
export class UxModule { }
