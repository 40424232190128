import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  enableProdMode,
  ErrorHandler,
  isDevMode,
  NgModule,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Level, NgLoggerModule } from '@nsalaun/ng-logger';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgxPaginationModule } from 'ngx-pagination';
import { environment } from '../environments/environment';
import { AppComponent } from './components/application/app.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { SearchComponent } from './components/search/search.component';
import { AppRoutingModule } from './routing/app-routing.module';
import { ApiInterceptor } from './internal-users/routing/api.interceptor';
import { ServicesModule } from './services/services.module';
import { MonitoringErrorHandler } from './utils/error.handler';
import { EventInterceptor } from './utils/event-interceptor';
// import {ServicesMocksModule} from './services-mocks/services-mocks.module';
import { UxModule } from './ux/ux.module';
import { OAuthModule } from 'angular-oauth2-oidc';
import { CoreModule } from './core/core.module';
import { FormsModule } from '@angular/forms';

if (environment.production) {
  enableProdMode();
}

let LOG_LEVEL = Level.ERROR;
if (isDevMode()) {
  LOG_LEVEL = Level.LOG;
}

@NgModule({
  declarations: [AppComponent, SearchComponent, SearchResultsComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    OAuthModule.forRoot(),
    RecaptchaModule,
    NgLoggerModule.forRoot(LOG_LEVEL),
    CoreModule.forRoot(),

    // Atlas Modules (Note - do NOT include lazy-loaded routed feature modules here)
    ServicesModule.forRoot(), // Should be enabled in app.module.ts and commented out in mocks.app.module.ts
    // ServicesMocksModule.forRoot(),   // Should be enabled in mocks.app.module.ts and commented out in app.module.ts
    UxModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: MonitoringErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EventInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
