import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseClass } from 'src/app/base/base-class';
import { environment } from '../../../environments/environment';
import { Order } from '../../models/order';
import { ReceiptLookupStatus } from '../../models/receipts';
import { MonitoringService } from '../../services/monitoring.service';
import { ReceiptsService } from '../../services/receipts-service';
import { NgForm } from '@angular/forms';
import { ReceiptRequest } from 'src/app/models/receipt-request.model';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent extends BaseClass implements OnInit {
  @ViewChild('cForm') cForm!: NgForm;
  @ViewChild('pForm') pForm!: NgForm;
  @ViewChild('sForm') sForm!: NgForm;
  receiptsServiceErrorMsg: string;
  creditCardForm: FormGroup;
  passengerForm: FormGroup;
  stripeSuffixCreditCardForm: FormGroup;
  lastFour: FormControl;
  cardHolderName: FormControl;
  stripeSuffix: FormControl;
  private _router: Router;
  private _receiptsService: ReceiptsService;
  recaptchaStr = '';
  recaptchaSiteKey: string;
  controlKeyHeld = false;
  isRecaptchaEnabled = true;
  orders: Array<Order>;
  isShowingDateRangeHelp = false;
  isShowingMobileDateRangeHelp = false;
  isShowFaq = false;
  isClosingFaq = false;
  isStripeSearch = false;
  isCreditCardSearch = false;
  currentDate = new Date();
  passengerFirstName: FormControl;
  origin: FormControl;
  destination: FormControl;
  maxDate!: any;
  minDate!: any;
  maxEndDate!: any;
  minEndDate!: any;
  passengerMaxDate!: any;
  passengerMinDate!: any;
  passengerMaxEndDate!: any;
  passengerMinEndDate!: any;
  receiptModel: ReceiptRequest;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private receiptsService: ReceiptsService,
    private modalService:
      NgbModal,
    ms: MonitoringService) {
    super();
    ms.logPageView('search page', '/');
    this._router = router;
    this._receiptsService = receiptsService;

    if (environment.name === 'mock') {
      this.cardHolderName.setValue('EZEQUIEL BRITO');
      this.lastFour.setValue('8535');
    }

  }

  ngOnInit() {
    this.initializeDates();
    this.initializeForms();
    // this.initializeReceiptModel();
  }

  initializeForms() {
    this.isRecaptchaEnabled = environment.isRecaptchaEnabled;
    this.recaptchaSiteKey = environment.recaptchaSiteKey;
    this.initializeCreditCardForm();
    this.initializePassengerForm();
    this.initializeStripeForm();
  }

  initializeReceiptModel() {
    this.receiptModel = {
      account: null,
      passengerFirstName: null,
      passengerLastName: null,
      startDate: null,
      endDate: null,
      cardHolderName: null,
      originAirportCode: null,
      destAirportCode: null,
    }
  }

  mapFormValuesToReceiptModel(isCreditCardSearch: boolean) {
    this.initializeReceiptModel();
    if (isCreditCardSearch) {
      this.receiptModel.cardHolderName = this.creditCardForm.get('cardHolderName')?.value;
      this.receiptModel.account = this.creditCardForm.get('lastFour')?.value;
      this.receiptModel.startDate = this.toCustomDate(this.creditCardForm.get('startDate')?.value);
      this.receiptModel.endDate = this.toCustomDate(this.creditCardForm.get('endDate')?.value);
    }
    else {
      this.receiptModel.passengerFirstName = this.passengerForm.get('passengerFirstName')?.value;
      this.receiptModel.passengerLastName = this.passengerForm.get('passengerLastName')?.value;
      this.receiptModel.originAirportCode = this.passengerForm.get('origin')?.value;
      this.receiptModel.destAirportCode = this.passengerForm.get('destination')?.value;
      this.receiptModel.startDate = this.toCustomDate(this.passengerForm.get('passengerStartDate')?.value);
      this.receiptModel.endDate = this.toCustomDate(this.passengerForm.get('passengerEndDate')?.value);
    }
  }

  initializeCreditCardForm() {
    this.creditCardForm = this.fb.group({
      lastFour: [null, [Validators.required, Validators.pattern('^[0-9]{4}$')]],
      cardHolderName: [null, [Validators.required, Validators.maxLength(75)]],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]]
    });
  }

  initializePassengerForm() {
    this.passengerForm = this.fb.group({
      origin: [null, [Validators.required, Validators.pattern('^[a-zA-Z]{3}$')]],
      destination: [null, [Validators.required, Validators.pattern('^[a-zA-Z]{3}$')]],
      passengerFirstName: [null, [Validators.required, Validators.maxLength(75)]],
      passengerLastName: [null, [Validators.required, Validators.maxLength(75)]],
      passengerStartDate: [null, [Validators.required]],
      passengerEndDate: [null, [Validators.required]]
    });
  }

  initializeStripeForm() {
    this.stripeSuffixCreditCardForm = this.fb.group({
      stripeSuffix: [null, [Validators.required]]
    });
  }

  initializeDates() {
    this.maxDate = this.getMaxDate();
    this.minDate = this.getMinDate();
    this.maxEndDate = this.getMaxDate();
    this.minEndDate = this.getMinDate();
    this.passengerMaxDate = this.getMaxDate();
    this.passengerMinDate = this.getMinDate();
    this.passengerMaxEndDate = this.getMaxDate();
    this.passengerMinEndDate = this.getMinDate();
  }

  onDateChange(): void {
    let isValidStartDate = this.isInvalid(this.creditCardForm, 'startDate');
    if (!isValidStartDate) {
      const startDate = this.creditCardForm.get('startDate')?.value;
      let endDate = new Date(this.toFormattedDate(startDate));
      endDate.setMonth(endDate.getMonth() + 1);
      let formattedDate = this.toFormattedDatepicker(endDate);
      this.minEndDate = { year: startDate.year, month: startDate.month, day: startDate.day };
      this.maxEndDate = { year: formattedDate.year, month: formattedDate.month, day: formattedDate.day  };
      console.log(this.maxEndDate);
    }
  }

  onPassengerDateChange(): void {
    let isValidStartDate = this.isInvalid(this.passengerForm, 'passengerStartDate');
    if (!isValidStartDate) {
      const startDate = this.passengerForm.get('passengerStartDate')?.value;
      let endDate = new Date(this.toFormattedDate(startDate));
      endDate.setMonth(endDate.getMonth() + 1);
      let formattedDate = this.toFormattedDatepicker(endDate);
      this.passengerMinEndDate = { year: startDate.year, month: startDate.month, day: startDate.day };
      this.passengerMaxEndDate = { year: formattedDate.year, month: formattedDate.month, day: formattedDate.day };
      console.log(this.passengerMaxEndDate);
    }
  }

  resetForm(form: string) {
    switch (form) {
      case 'creditCard':
        this.creditCardForm.reset();
        this.cForm.resetForm();
        this.initializeDates();
        this.initializeCreditCardForm();
        break;
      case 'passenger':
        this.passengerForm.reset();
        this.pForm.resetForm();
        this.initializeDates();
        this.initializePassengerForm();
        break;
      case 'stripe':
        this.stripeSuffixCreditCardForm.reset();
        this.sForm.resetForm();
        this.initializeStripeForm();
        break;
      default:
        break;
    }
  }

  resolved(captchaResponse: string) {
    this.recaptchaStr = captchaResponse;
    if (this.recaptchaStr) {
      if (this.isStripeSearch) {
        this.resolvedStripeSuffixSearch(captchaResponse);
      } else {
        this.search(this.isCreditCardSearch);
      }
    }
  }

  resolvedP(captchaResponse: string) {
    this.recaptchaStr = captchaResponse;
    if (this.recaptchaStr) {
      this.search(false);
    }
  }

  resolvedStripeSuffixSearch(captchaResponse: string) {
    this.isStripeSearch = false;
    this.recaptchaStr = captchaResponse;
    if (this.recaptchaStr) {
      this.stripeSuffixSearch();
    }
  }

  onSearchClick(recaptchaComponent: any) {
    this.isCreditCardSearch = true;
    if (!this.creditCardForm.valid) {
      return;
    }
    if (this.isRecaptchaEnabled) {
      if (this.recaptchaStr) {
        recaptchaComponent.reset();
      }
      recaptchaComponent.execute();
    } else {

      this.resolved('recaptcha');
    }
  }

  onPSearchClick(recaptchaComponent: any) {
    if (!this.passengerForm.valid) {
      return;
    }
    if (this.isRecaptchaEnabled) {
      if (this.recaptchaStr) {
        recaptchaComponent.reset();
      }
      recaptchaComponent.execute();
    } else {
      this.resolvedP('recaptcha');
    }
  }

  onStripeSuffixSearchClick(recaptchaComponent: any) {
    this.isStripeSearch = true;
    if (this.isRecaptchaEnabled) {
      if (this.recaptchaStr) {
        recaptchaComponent.reset();
      }
      recaptchaComponent.execute();
    } else {
      this.resolvedStripeSuffixSearch('recaptcha');
    }
  }

  isStripeSuffixSearchDisabled(): boolean {
    return this.stripeSuffix.value.trim().length != 9;
  }

  isValid(): boolean {
    this.creditCardForm.markAsTouched();
    Object.keys(this.creditCardForm.controls)
      .map(controlName => this.creditCardForm.controls[controlName])
      .forEach(control => control.markAsTouched());

    // TODO Anthony display validation error messages properly
    if (!this.creditCardForm.valid) {
      this.receiptsServiceErrorMsg = 'Validation errors';
    }

    return this.creditCardForm.valid;
  }

  isPValid(): boolean {
    this.passengerForm.markAsTouched();
    Object.keys(this.passengerForm.controls)
      .map(controlName => this.passengerForm.controls[controlName])
      .forEach(control => control.markAsTouched());

    if (!this.passengerForm.valid) {
      this.receiptsServiceErrorMsg = 'Validation errors';
    }
    return this.passengerForm.valid;
  }

  search(isCreditCardSearch: boolean): void {
    this.isCreditCardSearch = false;
    this.mapFormValuesToReceiptModel(isCreditCardSearch);

    if (isCreditCardSearch && this.creditCardForm.get('cardHolderName')?.value.trim().toUpperCase() === 'GUEST') {
      this.receiptsServiceErrorMsg = 'Cannot search by given name.';
      return;
    }

    this.receiptsServiceErrorMsg = '';
    this.orders = null;
    console.log(this.receiptModel);
    this._receiptsService.lookup(this.receiptModel)
      .subscribe(receiptsResponse => {

        switch (receiptsResponse.status) {
          case ReceiptLookupStatus.SUCCESS:
            this.orders = receiptsResponse.orders;
            break;
          case ReceiptLookupStatus.DOES_NOT_EXIST:
            this.orders = [];
            break;
          case ReceiptLookupStatus.TIMEOUT:
            this.receiptsServiceErrorMsg = 'Transaction service timed out.';
            break;
          case ReceiptLookupStatus.SYSTEM_FAILURE: default:
            this.receiptsServiceErrorMsg = 'A system error has occurred. Please try again later.';
            break;
        }
      });
  }

  stripeSuffixSearch(): void {
    this.receiptsServiceErrorMsg = '';
    let stripeSuffix = this.stripeSuffixCreditCardForm.get('stripeSuffix')?.value;
    this._receiptsService.searchByStripeSuffix(stripeSuffix)
      .subscribe(suffixSearchResponse => {
        switch (suffixSearchResponse.status) {
          case ReceiptLookupStatus.SUCCESS:
            this.orders = suffixSearchResponse.orders;
            break;
          case ReceiptLookupStatus.DOES_NOT_EXIST:
            this.orders = [];
            break;
          case ReceiptLookupStatus.TIMEOUT:
            this.receiptsServiceErrorMsg = 'Transaction service timed out.';
            break;
          case ReceiptLookupStatus.SYSTEM_FAILURE: default:
            this.receiptsServiceErrorMsg = 'A system error has occurred. Please try again later.';
            break;
        }
      });
  }

  onlyNumbersKeyDown(event: any) {
    if ('Control' === event.key || 'Meta' === event.key) {
      this.controlKeyHeld = true;
    }
  }

  onlyNumbersKeyUp(event: any) {
    if ('Control' === event.key || 'Meta' === event.key) {
      this.controlKeyHeld = false;
    }
  }

  zeroPadFocusOut(event: any, formControl: FormControl) {
    const val = formControl.value.trim();
    if (1 === val.length) {
      formControl.setValue('0' + val);
    }
  }

  milleniumPadFocusOut(event: any, formControl: FormControl) {
    const val = formControl.value.trim();
    if (2 === val.length) {
      formControl.setValue('20' + val);
    }
  }

  openDatesDialog(content) {
    this.isShowingDateRangeHelp = true;
    this.isShowingMobileDateRangeHelp = false;
    this.modalService.open(content, { ariaLabelledBy: 'dates of travel explanation dialog' }).result.then((result) => {
      // do nothing
    }, (reason) => {
      // do nothing
    });
  }

  showFaq(): void {
    this.isClosingFaq = false;
    this.isShowFaq = true;
  }

  hideFaq(): void {
    this.isClosingFaq = true;
    const thiz = this;
    setTimeout(() => thiz.isShowFaq = false, 300);
  }

}
