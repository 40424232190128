import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

// export const authConfig: AuthConfig = {
//   issuer: environment.provider,
//   clientId:   environment.clientId,// The "Auth Code + PKCE" client
//   responseType: 'code',
//   redirectUri: window.location.origin + '/' + environment.authCallbackRoute,
//   silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
//   scope: 'openid profile email', // Ask offline_access to support refresh token refreshes
//   useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
//   //silentRefreshTimeout: 5000, // For faster testing
//   timeoutFactor: 0.25, // For faster testing
//   sessionChecksEnabled: true,
//   showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
//   clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
//   nonceStateSeparator : 'semicolon' // Real semicolon gets mangled by IdentityServer's URI encoding
// };

export const authConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.provider,
  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/auth',
  // The SPA's id. The SPA is registered with this id at the auth-server
  clientId: environment.clientId,
  // set the scope for the permissions the client should request
  scope: 'openid profile email',
  // set response type, use code for Code Flow + PKCE
  responseType: 'code',
  // request an id_token, must be set to false
  oidc: false,
  // percent (95%) of token lifetime before token is refreshed
  timeoutFactor: 0.95,
};