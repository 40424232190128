import { Order } from './order';

export enum ReceiptLookupStatus {
  DOES_NOT_EXIST,
  SUCCESS,
  SYSTEM_FAILURE,
  TIMEOUT,
  BAD_REQUEST,
  START_DATE_AFTER_END_DATE,
  SEARCH_LIMIT_EXCEEDED
}

export interface ReceiptLookupResponse {
  status: ReceiptLookupStatus;
  orders: Array<Order>;
}
