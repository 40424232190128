import {HttpInterceptor, HttpResponse, HttpRequest, HttpEvent, HttpHandler} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {GlobalEvent, GlobalEventService} from '../services/global-event.service';

@Injectable()
export class EventInterceptor implements HttpInterceptor {

  constructor(private _eventService: GlobalEventService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let req: HttpRequest<any> = request;

    if (request.headers.has('background')) {
      req = request.clone({ headers: request.headers.delete('background') });
    } else if (request.url.startsWith('/1/guestServices/receipts/atlas/')) {
      this._eventService.broadcastAjax(GlobalEvent.AJAX_START);

      return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this._eventService.broadcastAjax(GlobalEvent.AJAX_END);
        }
      }, (err: any) => {
        this._eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
      }));
    }
    return next.handle(req);
  }

}
