import { FormControl, FormGroup } from "@angular/forms";

export class BaseClass {
    constructor() { }

    public toFormattedDate(value: any) {
        const date = new Date(value.year, value.month - 1, value.day);
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }

    public toCustomDate(value: any) {
        return new Date(value.year, value.month - 1, value.day);
    }

    public toFormattedDatepicker(value: Date) {
        return {
            month: value.getMonth() + 1,
            day: value.getDate(),
            year: value.getFullYear()
        }
            ;
    }

    public getMaxDate() {
        const date = new Date();
        const maxYear = date.getFullYear() + 1;
        return { year: maxYear, month: date.getMonth() + 1, day: date.getDate() };
    }

    public getMinDate() {
        const date = new Date();
        const minYear = date.getFullYear() - 2;
        return { year: minYear, month: date.getMonth() + 1, day: date.getDate() };
    }

    public hasError(formName: FormGroup, controlName: string, errorName: string): boolean {
        let isNgbError = false;
        if (formName.controls[controlName].errors != null && formName.controls[controlName].errors.ngbDate != null) {
            let a = formName.controls[controlName].errors.ngbDate;
            let b = a[errorName];
            if (b != undefined)
                isNgbError = true;
        }
        return (formName.controls[controlName].hasError(errorName) && formName.controls[controlName].touched) ||
            (isNgbError && formName.controls[controlName].touched);
    }

    public isInvalid(formName: FormGroup, controlName: string) {
        return formName.controls[controlName].invalid && formName.controls[controlName].touched;
    }

    public onlyLettersKeyPress(event: any) {
        const pattern = /[a-zA-Z]/;
        if ('Tab' !== event.key && !pattern.test(event.key) && !('v' === event.key)) {
            event.preventDefault();
        }
    }

    public onlyNumbersKeyPress(event: any) {
        const pattern = /[0-9\+\-\ ]/;
        if ('Tab' !== event.key && !pattern.test(event.key) && !('v' === event.key)) {
            event.preventDefault();
        }
    }
}