import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent {

  @Input() label: string;
  @Input() labelClass: string;
  @Input() isInvalid: boolean;

  constructor() { }

}
