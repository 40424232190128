<div *ngIf="!isShowingMobileDateRangeHelp" class="atlas-search">
  <div *ngIf="isShowFaq" aria-label="Children" tabindex="-1" class="rightSideDrawer"
    [ngClass]="{ closeRightSideDrawer: isClosingFaq }" role="dialog">
    <div class="drawerBackground" (click)="hideFaq()"></div>
    <div class="drawerContent">
      <h2 class="slider-title">Receipts – Frequently asked questions</h2>
      <div class="drawerContent-dynamic">
        <h4>How quickly will my receipt be available?</h4>
        <p>
          Your receipt will be available here at
          <a class="atlas-link" target="_blank" rel="noopener"
            href="https://receipts.alaskaair.com">receipts.alaskaair.com</a>
          once the flight lands and the data has been transmitted. In most cases
          this will be within a few hours of landing. However, we are unable to
          transmit data from Canada, Mexico, and some remote cities in Alaska,
          so these receipts could take up to 48 hours to become available. You
          should be able to access a receipt here once you see the charge for an
          inflight purchase or checked bag payment on your credit card
          statement.
        </p>
        <h4>Why can't I get a receipt when I make my purchase?</h4>
        <p>
          To keep the payment devices that the flight attendants and customer
          service agents use as light as possible, they do not include a
          printer.
        </p>
        <h4>Why can't I get a receipt emailed to me?</h4>
        <p>
          To collect your email address during inflight purchases, the flight
          attendant would either have to hand you the device or ask you to say
          it aloud. Neither of those options provides the security we want for
          you or your fellow guests, and it would slow down our service. We’re
          working on giving customer service agents the ability to email your
          receipt when you pay for a checked bag using their handheld device;
          stay tuned for future updates.
        </p>
      </div>
      <div class="drawerClose" title="Close dialog" (click)="hideFaq()">
        <img alt="close" src="https://www.alaskaair.com/img/seatmaps/close_drawer.png" />
      </div>
    </div>
  </div>
  <div class="atlas-title">
    Receipts for purchases made during your trip,
    <br />lounge memberships, and Premium Class upgrades
    <br />
  </div>
  <div class="atlas-instructions atlas-instructions-main">
    Fill out this form to view, download, or print a receipt if you:
    <ul>
      <li>
        Bought food, drinks during your recent Alaska Airlines flight
      </li>
      <li>
        Baggage fees paid at select airports
      </li>
      <li>Purchased a lounge membership</li>
      <li>Purchased a Premium Class upgrade</li>
    </ul>
    If you need a receipt for any other kinds of purchases, visit our
    <a class="atlas-link"
      href="https://www.alaskaair.com/content/travel-info/policies/other-request-ticket-receipt">requesting a receipt
      page</a>.
  </div>
  <div class="atlas-instructions">
    Before you begin, you’ll need:
    <ul>
      <li>
        Your full name as it appears on the credit card you used to make the
        purchase
      </li>
      <li>The last four digits of that card</li>
      <li>Dates of the flight</li>
    </ul>
  </div>
  <div id="receiptPageError" *ngIf="receiptsServiceErrorMsg">
    {{ receiptsServiceErrorMsg }}
  </div>
  <form [formGroup]="creditCardForm" (ngSubmit)="onSearchClick(reCaptcha)" id="credit-card-search-form"
    autocomplete="off" novalidate #cForm="ngForm">
    <div class="container-fluid">
      <div class="row atlas-grid-header">
        <div class="card-col pl-0 pr-md-4">
          Credit card information
          <div class="container-fluid card-info-container">
            <div class="row">
              <div class="col-md px-0 atlas-input-title atlas-date-group">
                <app-input [label]="'Name as it appears on card'" [labelClass]="'pname'"
                  [isInvalid]="isInvalid(creditCardForm,'cardHolderName')">
                  <input type="text" id="cardHolderName" placeholder=" " formControlName="cardHolderName" />
                </app-input>
                <div class="custom-error">
                  <span *ngIf="hasError(creditCardForm, 'cardHolderName', 'required')">
                    Card Holder Name is required.
                  </span>
                  <span *ngIf="hasError(creditCardForm, 'cardHolderName', 'maxlength')">
                    Card Holder Name must have at most 75 characters.
                  </span>
                </div>
              </div>
              <div class="col-md-4 pl-0 pl-md-2 pr-0">
                <app-input [label]="'Last four digits'" [labelClass]="'last-four'"
                  [isInvalid]="isInvalid(creditCardForm,'lastFour')">
                  <input type="text" formControlName="lastFour" id="lastFour" placeholder=" " maxlength="4"
                    (keypress)="onlyNumbersKeyPress($event)" />
                </app-input>
                <div class="custom-error">
                  <span *ngIf="hasError(creditCardForm, 'lastFour', 'required')">
                    Last Four is required.
                  </span>
                  <span *ngIf="hasError(creditCardForm, 'lastFour', 'pattern')">
                    Last Four must have 4 digits.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="date-col pr-0">
          <div class="d-md-none" (click)="
              isShowingDateRangeHelp = false;
              isShowingMobileDateRangeHelp = true
            ">
            Dates of purchase or travel
            <img alt="help" class="help-icon" src="/assets/icon-help.svg" />
          </div>
          <div class="d-none d-md-block" (click)="openDatesDialog(datesDlgContent)">
            Dates of purchase or travel
            <img alt="help" class="help-icon" src="/assets/icon-help.svg" />
          </div>
          <div class="container-fluid pr-0">
            <div class="row">
              <div class="col-md px-0 atlas-input-title atlas-date-group">
                <app-input [label]="'Start Date'" [labelClass]="'date'"
                  [isInvalid]="isInvalid(creditCardForm,'startDate')">
                  <input type="text" placeholder="YYYY-MM-DD" (dateSelect)="onDateChange()" (blur)="onDateChange()"
                    id="startDate" [minDate]="minDate" [maxDate]="maxDate" (click)="d.toggle()" ngbDatepicker
                    #d="ngbDatepicker" formControlName="startDate" />
                </app-input>
                <div class="custom-error">
                  <span *ngIf="hasError(creditCardForm, 'startDate', 'invalid')">
                    Incorrect Start Date input.
                  </span>
                  <span *ngIf="hasError(creditCardForm, 'startDate', 'required')">
                    Start Date is required.
                  </span>
                  <span *ngIf="hasError(creditCardForm, 'startDate', 'minDate')">
                    Start Date cannot be less than {{this.toFormattedDate(minDate)}}.
                  </span>
                  <span *ngIf="hasError(creditCardForm, 'startDate', 'maxDate')">
                    Start Date cannot be greater than {{this.toFormattedDate(maxDate)}}.
                  </span>
                </div>
              </div>
              <div class="col-md px-0 atlas-input-title atlas-date-group">
                <app-input [label]="'End Date'" [labelClass]="'date'" [isInvalid]="isInvalid(creditCardForm,'endDate')">
                  <input type="text" placeholder="YYYY-MM-DD" [minDate]="minEndDate" [maxDate]="maxEndDate" id="endDate"
                    (click)="d2.toggle()" ngbDatepicker #d2="ngbDatepicker" formControlName="endDate" />
                </app-input>
                <div class="custom-error">
                  <span *ngIf="hasError(creditCardForm, 'endDate', 'invalid')">
                    Incorrect End Date input.
                  </span>
                  <span *ngIf="hasError(creditCardForm, 'endDate', 'required')">
                    End Date is required.
                  </span>
                  <span *ngIf="hasError(creditCardForm, 'endDate', 'minDate')">
                    End Date cannot be less than Start Date.
                  </span>
                  <span *ngIf="hasError(creditCardForm, 'endDate', 'maxDate')">
                    Please limit your search to 1 month maximum.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-lg-end">
        <div class="col-sm-12 col-md-10">
          <button id="searchBtn" style="margin-right: -21px;" class="btn clear-button" type="button" (click)="resetForm('creditCard')">
            CLEAR
          </button>

        </div>
        <div class="col-sm-12 col-md-2">

          <button id="searchBtn" class="btn btn-primary" type="submit" style="width: 110%;"
            [disabled]="!this.creditCardForm.valid">
            SEARCH
          </button>
        </div>

      </div>
    </div>
  </form>
  <form [formGroup]="passengerForm" (ngSubmit)="onPSearchClick(reCaptcha)" id="passenger-search-form" novalidate
    autocomplete="off" #pForm="ngForm">
    <br />
    <div class="container-fluid">
      <div class="row atlas-grid-header">
        <div class="atlas-title">
          In-Flight Tap to Pay
          <br />
        </div>
        <div class="atlas-instructions" style="font-weight: normal;">
          If you used Tap to Pay when making an In-Flight purchase on a flight attendant's iPhone (whether with a credit card or your own device), use either
          of the methods below to retrieve a receipt:
          <br /><br />
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row atlas-grid-header">
        <div class="col-md-8 px-0">
          Passenger Name
          <div class="container-fluid card-info-container">
            <div class="row">
              <div class="col-md px-0 atlas-input-title atlas-date-group">
                <app-input [label]="'First Name'" [labelClass]="'pname'"
                  [isInvalid]="isInvalid(passengerForm,'passengerFirstName')">
                  <input type="text" id="passengerFirstName" placeholder=" " maxlength="75" autocomplete="off"
                    formControlName="passengerFirstName" />
                </app-input>
                <div class="custom-error">
                  <span *ngIf="hasError(passengerForm, 'passengerFirstName', 'required')">
                    First Name is required.
                  </span>
                  <span *ngIf="hasError(passengerForm, 'passengerFirstName', 'maxlength')">
                    First Name must have at most 75 characters.
                  </span>
                </div>
              </div>

              <div class="col-md px-0 atlas-input-title atlas-date-group">
                <app-input [label]="'Last Name'" [labelClass]="'pname'"
                  [isInvalid]="isInvalid(passengerForm,'passengerLastName')">
                  <input type="text" id="passengerLastName" placeholder=" " maxlength="75" autocomplete="off"
                    formControlName="passengerLastName" />
                </app-input>
                <div class="custom-error">
                  <span *ngIf="hasError(passengerForm, 'passengerLastName', 'required')">
                    Last Name is required.
                  </span>
                  <span *ngIf="hasError(passengerForm, 'passengerLastName', 'maxlength')">
                    Last Name must have at most 75 characters.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="col-md-4 px-0">
          Origin and Destination
          <div class="container-fluid card-info-container">
            <div class="row">
              <div class="col-md px-0 atlas-input-title atlas-date-group">
                <app-input [label]="'Origin'" [labelClass]="'origin'" [isInvalid]="isInvalid(passengerForm,'origin')">
                  <input type="text" placeholder=" " maxlength="3" (keypress)="onlyLettersKeyPress($event)" id="origin"
                    formControlName="origin" />
                </app-input>
                <div class="custom-error">
                  <span *ngIf="hasError(passengerForm, 'origin', 'required')">
                    Origin is required.
                  </span>
                  <span *ngIf="hasError(passengerForm, 'origin', 'pattern')">
                    Invalid Airport code. Please enter a three-letter code.
                  </span>
                </div>
              </div>
              <div class="col-md px-0 atlas-input-title atlas-date-group">
                <app-input [label]="'Destination'" [labelClass]="'destination'"
                  [isInvalid]="isInvalid(passengerForm,'destination')">
                  <input type="text" placeholder=" " maxlength="3" (keypress)="onlyLettersKeyPress($event)"
                    id="destination" formControlName="destination" />
                </app-input>
                <div class="custom-error">
                  <span *ngIf="hasError(passengerForm, 'destination', 'required')">
                    Destination is required.
                  </span>
                  <span *ngIf="hasError(passengerForm, 'destination', 'pattern')">
                    Invalid Airport code. Please enter a three-letter code.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 px-0">
          <div class="d-md-block" (click)="openDatesDialog(datesDlgContent)">
            Dates of purchase or travel
            <img alt="help" class="help-icon" src="/assets/icon-help.svg" />
          </div>
          <div class="container-fluid pr-0">
            <div class="row">
              <div class="col-md px-0 atlas-input-title atlas-date-group">
                <app-input [label]="'Start Date'" [labelClass]="'pdate'"
                  [isInvalid]="isInvalid(passengerForm,'passengerStartDate')">
                  <input type="text" placeholder="YYYY-MM-DD" (dateSelect)="onPassengerDateChange()"
                    (blur)="onPassengerDateChange()" [minDate]="passengerMinDate" [maxDate]="passengerMaxDate"
                    id="passengerStartDate" (click)="d3.toggle()" ngbDatepicker #d3="ngbDatepicker"
                    formControlName="passengerStartDate" />
                </app-input>
                <div class="custom-error">
                  <span *ngIf="hasError(passengerForm, 'passengerStartDate', 'invalid')">
                    Incorrect Start Date input.
                  </span>
                  <span *ngIf="hasError(passengerForm, 'passengerStartDate', 'required')">
                    Start Date is required.
                  </span>
                  <span *ngIf="hasError(passengerForm, 'passengerStartDate', 'minDate')">
                    Start Date cannot be less than {{minDate}}.
                  </span>
                  <span *ngIf="hasError(passengerForm, 'passengerStartDate', 'maxDate')">
                    Start Date cannot be greater than {{maxDate}}.
                  </span>
                </div>
              </div>
              <div class="col-md px-0 atlas-input-title atlas-date-group">
                <app-input [label]="'End Date'" [labelClass]="'pdate'"
                  [isInvalid]="isInvalid(passengerForm,'passengerEndDate')">
                  <input type="text" placeholder="YYYY-MM-DD" [minDate]="passengerMinEndDate" id="passengerEndDate"
                    [maxDate]="passengerMaxEndDate" (click)="d4.toggle()" ngbDatepicker #d4="ngbDatepicker"
                    formControlName="passengerEndDate" />
                </app-input>
                <div class="custom-error">
                  <span *ngIf="hasError(passengerForm, 'passengerEndDate', 'invalid')">
                    Incorrect End Date input.
                  </span>
                  <span *ngIf="hasError(passengerForm, 'passengerEndDate', 'required')">
                    End Date is required.
                  </span>
                  <span *ngIf="hasError(passengerForm, 'passengerEndDate', 'minDate')">
                    End Date cannot be less than {{minStartDate}}.
                  </span>
                  <span *ngIf="hasError(passengerForm, 'passengerEndDate', 'maxDate')">
                    Please limit your search to 1 month maximum.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="d-none d-md-block">
            <br>
          </div>
          <button id="searchBtn" style="margin-right: -21px;" class="btn clear-button" type="button" (click)="resetForm('passenger')">
            CLEAR
          </button>
        </div>
        <div class="col-md-2 px-0">
          <div class="d-none d-md-block">
            <br>
          </div>
          <div class="col-sm-12 col-md-12">
            <button id="searchBtn" style="width: 110%;" class="btn btn-primary btn-block" type="submit"
              [disabled]="!this.passengerForm.valid">
              SEARCH
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <form [formGroup]="stripeSuffixCreditCardForm" (ngSubmit)="onStripeSuffixSearchClick(reCaptcha)" autocomplete="off"
    #sForm="ngForm" id="stripe-suffix-search-form" novalidate>
    <br />
    <div class="container-fluid">
      <div class="row atlas-grid-header">
        <div class="atlas-instructions" style="font-weight: normal;">
          You can also look up a specific transaction by entering the 9-digit code (preceded by ALASKA AIR*) from your
          bank statement.
          <br /><br />
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row atlas-grid-header">
        <div class="card-col pl-0 pr-md-4">
          9-Digit Bank Statement Code
        </div>
      </div>
      <div class="row atlas-grid-header">
        <div class="col-md-5 px-0">
          <app-input [label]="'Statement Code'" [isInvalid]="isInvalid(stripeSuffixCreditCardForm,'stripeSuffix')">
            <input type="text" id="stripeSuffix" placeholder="Statement Code" maxlength="9"
              formControlName="stripeSuffix" />
          </app-input>
          <div class="custom-error">
            <span *ngIf="hasError(stripeSuffixCreditCardForm, 'stripeSuffix', 'required')">
              Statement Code is required.
            </span>
          </div>
        </div>
        
        <div class="col-md-5">
          <button id="searchBtn" style="margin-right: -21px;" class="btn clear-button" type="button" (click)="resetForm('stripe')">
            CLEAR
          </button>
        </div>
        <!-- <div class="col-sm-12 col-md-4 col-lg-4"> -->
          <div class="col-sm-12 col-md-2">
            <button id="stripeSuffixSearchBtn" style="width: 110%;" class="btn btn-primary btn-block" type="submit"
              [disabled]="!this.stripeSuffixCreditCardForm.valid">
              SEARCH
            </button>
          </div>
        <!-- </div> -->
      </div>
    </div>
  </form>

  <div class="atlas-instructions atlas-faq">
    Have questions about these receipts?
    <span class="atlas-link" (click)="showFaq()">We have answers.</span>
  </div>
  <div class="search-results-container">
    <app-search-results *ngIf="orders" [orders]="orders"></app-search-results>
  </div>
</div>
<re-captcha #reCaptcha (resolved)="resolved($event)" [size]="'invisible'" [siteKey]="recaptchaSiteKey"></re-captcha>
<ng-template #datesDlgContent let-c="close" let-d="dismiss">
  <div class="atlas-dates-dialog">
    <img alt="close" src="/assets/icon-close-desktop.svg" class="close-atlas-dlg atlas-desktop float-right"
      (click)="c('')" />
    To find receipts for multiple flights, provide the start date of the first
    flight, and the end date of the last flight.
    <br />
    <br />
    For one-way flights, enter the same start and end date.
    <br />
    <br />
    Note: Receipts are only available for purchases within the previous 18-month
    period. <br />
    Lounge receipts are available on or after 8/26/2021
  </div>
</ng-template>
<div *ngIf="isShowingMobileDateRangeHelp">
  <img alt="close" src="/assets/icon-close.svg" title="Close dialog" class="close-atlas-dlg float-right"
    (click)="isShowingMobileDateRangeHelp = false" />
  <br />
  <br />
  To find receipts for multiple flights, provide the start date of the first
  flight, and the end date of the last flight.
  <br />
  <br />
  For one-way flights, enter the same start and end date.
  <br />
  <br />
  Note: Receipts are only available for purchases within the previous 18-month
  period. <br />
  Lounge receipts are available on or after 8/26/2021
</div>