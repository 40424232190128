import {Component, Input} from '@angular/core';
import { empty } from 'rxjs';
import {Order} from '../../models/order';
// import {TealiumUtagService} from '../../services/utag.service';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent {

  private _orders: Array<Order>;
  page = 1;

  constructor(/*private tealium: TealiumUtagService*/) { }

  @Input()
  set orders(orders: Array<Order>) {   
    this._orders = orders;
    const thiz = this;
    if (this._orders) {
      setTimeout(thiz.scrollToFirstRow, 0);
    }
    // this.tealium.view(
    //   {
    //     server: environment.machineName,
    //     page_name: 'traveling : inflight-receipt-search results',
    //     channel: 'traveling'
    //   });
  }

  private scrollToFirstRow() {
    document.querySelector('.first-results-row').scrollIntoView({ behavior: 'smooth' });
  }

  get orders(): Array<Order> { return this._orders; }

  orderTrackByFn(index: number, order: Order) {
    return order.id;
  }

  hasAtLeastOneOrder(): boolean {
    return this.orders && this.orders.length > 0;
  }

  isZeroMatchingOrders(): boolean {
    return this.orders && this.orders.length === 0;
  }

  isAmountNegative(order: Order): string {
    if (order.AmountCharged < 0) { return "Refunded"; }
    else if (order.AmountCharged > 0) { return "" };
  }

  isOrderRefunded(order: Order): boolean {
    return order.AmountCharged < 0;
  }

  getOrderReceiptDownloadLink(order: Order): string {
    return encodeURI(`/1/guestServices/receipts/atlas/receipts/${order.id}?refund=${this.isOrderRefunded(order)}&account=${order.AccountLastFour}&cardholderName=${order.CardholderName}`);
  }

}
