import {Component, OnInit} from '@angular/core';
// import {TealiumUtagService} from '../../services/utag.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(/*private tealium: TealiumUtagService*/) {
    // switch (environment.name) {
    //   case 'dev':
    //   case 'qa':
    //   case 'prod':
    //     this.tealium.setConfig({
    //       'account'     : 'alaska',
    //       'profile'     : 'main',
    //       'environment' : environment.name
    //     });
    //     break;
    // }
  }

  ngOnInit() {
    // this.tealium.view(
    //   {
    //     server: environment.machineName,
    //     page_name: 'traveling : inflight-receipt',
    //     channel: 'traveling'
    //   });
  }

}
