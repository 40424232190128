// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular.json`.

export const environment = {
  appInsightsConfig: {
    instrumentationKey: '#{atlas_arm_appInsightsTelemetryValue}#',
  },
  hmr: false,
  isDebugMode: false,
  isRecaptchaEnabled: true,
  recaptchaSiteKey: '6LceemEUAAAAACkgcfU7KT5vSusrhE3zxQYJ9WFq',
  name: 'prod',
  production: true,
  port: '',
  machineName: '#{atlas_arm_appName}#',
  authCallbackRoute: 'auth',
  silentRenewAuthCallbackRoute: 'assets/auth.html',
  idProviderClientId: 'ZPNfMaze7cX84wQ',
  idProviderUrl: 'https://fedauth.alaskasworld.com',
  clientId: '3acf547c-b66b-4848-8971-caa753e9ac98',
  provider: 'https://fedauth.alaskasworld.com',
  timeOut: 750000
};
