import {Injectable, NgZone, EventEmitter} from '@angular/core';
 import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import {timer, Observable, BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {catchError, filter, map, pluck, take, timeInterval} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Logger} from '@nsalaun/ng-logger';
import {Router, RouterStateSnapshot} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {User} from '../models/user';
import { authConfig } from '../models/auth.config'; 

const authCallbackRoute = `/${environment.authCallbackRoute}`;
const loggedInKey = 'adUser';
const originalRequestPathKey = 'originalRequestPath';

export interface AuthServiceAPI {
  initialize(): void;
  moduleDestroy(): void;
  authorize(): void;
  getAccessToken(): any;
}

export interface AuthServiceAPIMock {
  initialize(): void;
  moduleDestroy(): void;
  authorize(): void;
  getAccessToken(): any;
}

@Injectable()
export class AuthService {
  onTokenReceived: EventEmitter<void> = new EventEmitter<void>();
  onError: EventEmitter<void> = new EventEmitter<void>();
  
  private initialized = false;

  private moduleSetupSubscription;
  
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

 
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$
  ]).pipe(map(values => values.every(b => b)));

  private navigateToLoginPage() {
    this.router.navigateByUrl('/internal');
  }

   errorResponsesRequiringUserInteraction:Array<string> = [
    'interaction_required',
    'login_required',
    'account_selection_required',
    'consent_required',
  ];
private errorSubscription;
private validTokenSubscription;
private sessionSubscription;
  constructor(private oAuthService: OAuthService,private logger: Logger,protected router: Router,
     private cookieService: CookieService ) {


    this.errorSubscription = this.oAuthService.events.subscribe();
      
      window.addEventListener('storage', (event: any) => {
        if (event.origin == "")
            return;
        // The `key` is `null` if the event was caused by `.clear()`
        if (event.key !== 'access_token' && event.key !== null) {
          return;
        }
  
        this.isAuthenticatedSubject$.next(this.oAuthService.hasValidAccessToken());
  
        if (!this.oAuthService.hasValidAccessToken()) {
          this.navigateToLoginPage();
        }
      });
  
     this.validTokenSubscription = this.oAuthService.events
        .subscribe(_ => {
          if(this.oAuthService.hasValidAccessToken())
          {
            sessionStorage.setItem("_isAuthorized","true");
          }
          this.isAuthenticatedSubject$.next(this.oAuthService.hasValidAccessToken());
        });
  
     this.sessionSubscription = this.oAuthService.events
        .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
        .subscribe(e => this.navigateToLoginPage());
  
      this.oAuthService.setupAutomaticSilentRefresh();
    }

    public runInitialLoginSequence(): Promise<void> {
  
        return this.oAuthService.loadDiscoveryDocument()
        .then(() => this.oAuthService.tryLogin()) 
        .then(() => {
          this.isDoneLoadingSubject$.next(true);
          if (this.oAuthService.state && this.oAuthService.state !== 'undefined' && this.oAuthService.state !== 'null') {
            let stateUrl = this.oAuthService.state;
            if (stateUrl.startsWith('/') === false) {
              stateUrl = decodeURIComponent(stateUrl);
            }
            this.router.navigateByUrl(stateUrl);
          }
        })
        .catch(() => this.isDoneLoadingSubject$.next(true));  
    }

  initialize(): void {
    this.logger.debug(`AuthService initialized from path ${window.location.pathname}`);

    if (this.initialized) {
      this.logger.debug(`Ignoring additional AuthService initialize() from path ${window.location.pathname}`);
      return;
    }
    this.initialized = true;

    // Make it so that the silent renew iframe can get a reference to AuthService so that it can call authorizedCallback.
    this.logger.debug('Setting global reference to window.authService');
    (<any>window).authService = this;
  }

  moduleDestroy(): void {
    if (this.moduleSetupSubscription) {
      this.logger.debug('calling moduleSetupSubscription.unsubscribe()');
      this.moduleSetupSubscription.unsubscribe();
      this.moduleSetupSubscription = null;
    }
    if(this.errorSubscription)
    {
      this.errorSubscription.unsubscribe();
      this.errorSubscription = null;
    }
    if(this.validTokenSubscription)
    {
      this.validTokenSubscription.unsubscribe();
      this.validTokenSubscription = null;
    }
    if(this.sessionSubscription)
    {
      this.sessionSubscription.unsubscribe();
      this.sessionSubscription = null;
    }
  }

  public login(targetUrl?: string) {
    this.oAuthService.initLoginFlow(targetUrl || this.router.url);
  }
 
  public authorizedSubject = new Subject<boolean>();

  authorize(): void {
    this.login(window.location.pathname);
    if ('/internal/inquiry' !== window.location.pathname) {
      sessionStorage.setItem(originalRequestPathKey, window.location.pathname);
    }
    this.logger.debug('On authorize, the requested route pathname: ' + window.location.pathname);
  }

  getAccessToken(): any {
    try {
      var tkn =  this.oAuthService.getAccessToken();
      return tkn;
    } catch (err) {
      this.logger.debug('Failed to retrieve Access token');
      return 'Access token could not be retrieved';
    }
  }
 
  public hasValidAccessToken(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }

  authorizedCallback(): void {
    const originalRequestPathValue = sessionStorage.getItem(originalRequestPathKey);
    sessionStorage.removeItem(originalRequestPathKey);
  }


}
