<table *ngIf="hasAtLeastOneOrder()" class="table" aria-label="Order Receipts">
  <thead>
    <tr>
      <th scope="col">Date</th>
      <th scope="col">Description</th>
      <th scope="col" class="amount-column">Amount Charged</th>
      <th scope="col" class="d-none d-md-table-cell">&nbsp;</th>
      <th scope="col" class="d-none d-md-table-cell">&nbsp;</th>
    </tr>
  </thead>
  <tbody>
    <ng-container
      *ngFor="
        let order of orders | paginate: { itemsPerPage: 10, currentPage: page };
        trackBy: orderTrackByFn;
        let even = even;
        let odd = odd;
        first as isFirst
      "
    >
      <tr [ngClass]="{ odd: odd, even: even, 'first-results-row': isFirst }">
        <td>{{ order.TravelDate | date: 'MM/dd/yyyy' }}</td>
        <td *ngIf="order.Source == 'Inflight'">
          Alaska {{ order.FlightNumber }} - {{ order.Origin }} to
          {{ order.Destination }}
        </td>
        <td *ngIf="order.Source == 'Inflight/stripe'">
          Alaska {{ order.FlightNumber }} - {{ order.Origin }} to
          {{ order.Destination }}
        </td>
        <td *ngIf="order.Source == 'Airport' || order.Source == 'ContactCenter'">
          {{ order.Origin }}
        </td>
        <td *ngIf="order.Source == 'Lounge'">Alaska Lounge Membership</td>
        <td text-align="right" class="amount-column">
          {{ order.AmountCharged | currency: 'USD':'symbol' }}
        </td>
        <td text-align="right" class="refund-column">         
           <span *ngIf="isOrderRefunded(order)" class="badge rounded-pill bg-success">{{ isAmountNegative(order) }}</span>
        </td>
        <td class="recipt-cell d-none d-md-table-cell">
          <a
             href="{{ getOrderReceiptDownloadLink(order) }}"
             target="_blank"
             class="action-link"
             >Download receipt</a
>
          <span class="altas-size">(49K pdf)</span>
        </td>
      </tr>
      <tr class="d-md-none download-row" [ngClass]="{ odd: odd, even: even }">
        <td class="recipt-cell" colspan="4">
          <a
            href="{{ getOrderReceiptDownloadLink(order) }}"
            target="_blank"
            class="action-link"
            >Download receipt</a
          >
          <span class="altas-size">(49K pdf)</span>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
<div *ngIf="orders.length > 10">
  <ngb-pagination
    class="d-flex justify-content-end pagination"
    [collectionSize]="orders.length"
    [(page)]="page"
    [boundaryLinks]="true"
    [maxSize]="5"
    [rotate]="true"
    [ellipses]="false"
    size="sm"
  ></ngb-pagination>
</div>
<div *ngIf="isZeroMatchingOrders()" class="no-matches first-results-row">
  <div class="font-weight-bold">
    We can’t find a receipt based on that information.
  </div>
  Check that you have entered the correct flight dates, your name as it appears
  on the card, and the last four digits of the credit card used during the
  flight, and then try again. Also, verify that you see a charge for a checked
  bag or an inflight purchase on your credit card statement. Receipts will
  generally be available within a few hours of landing; in some cases it may
  take up to 48 hours.
</div>


