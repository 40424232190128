import {Injectable} from '@angular/core';
import {map, filter} from 'rxjs/operators';
import {Subject, Observable} from 'rxjs';

export enum GlobalEvent {
  AJAX_START = 1,
  AJAX_END
}

export interface BroadcastEvent {
  key: GlobalEvent;
  data?: any;
}

/**
 * This service allows clients to publish and subscribe to events that are of general interest (i.e. useful in
 * many situations/components/services). The service also stores state based on events so that components that
 * are instantiated after the events have already fired will be able to find out what the state is upon their creation.
 */
@Injectable()
export class GlobalEventService {

  private _subject = new Subject<BroadcastEvent>();
  private _pendingRequestCount = 0;

  broadcastAjax(key: GlobalEvent.AJAX_START | GlobalEvent.AJAX_END, error?: any) {
    switch (key) {
      case GlobalEvent.AJAX_START:
        this._pendingRequestCount++;
        this._subject.next({key});
        break;
      case GlobalEvent.AJAX_END:
        if (this._pendingRequestCount > 0 && !(--this._pendingRequestCount)) {
          this._subject.next({key, data: error});
        }
        break;
    }
  }

  on<T>(key: GlobalEvent): Observable<T> {
    return this._subject.asObservable().pipe(
      filter(event => event.key === key),
      map(event => <T>event.data));
  }

}
