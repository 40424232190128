import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {ReceiptsService} from './receipts-service';
import {MonitoringService} from './monitoring.service';
import {GlobalEventService} from './global-event.service';
// import {TealiumUtagService} from './utag.service';
import {CookieService} from 'ngx-cookie-service';
import { AuthService } from '../../app/internal-users/services/auth.service'

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ]
})
export class ServicesModule {

  static forRoot(): ModuleWithProviders<ServicesModule> {
    return {
      ngModule: ServicesModule,
      providers: [
        AuthService,
        CookieService,
        GlobalEventService,
        MonitoringService,
        ReceiptsService
        // TealiumUtagService
      ]
    };
  }

}
