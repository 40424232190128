import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SearchComponent} from '../components/search/search.component';
import { AuthGuardWithForcedLogin } from '../core/auth-guard-with-forced-login.service';

import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [
  { path: '', component: SearchComponent },
  {
    path: 'search',
    component: SearchComponent
  },
  {
    path: 'auth',
    loadChildren: () => import('../internal-users/internal-users.module').then(m => m.InternalUsersModule),
    canActivate: [AuthGuardWithForcedLogin]
  },
  {
    path: 'internal',
    loadChildren: () => import('../internal-users/internal-users.module').then(m => m.InternalUsersModule),
    //canLoad: [AuthGuardWithForcedLogin],
    canActivate: [AuthGuardWithForcedLogin]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
