import { throwError as observableThrowError, Observable, defer, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ReceiptLookupResponse, ReceiptLookupStatus } from '../models/receipts';
import { Order } from '../models/order';
import { OrderQuery } from '../models/order-query';
import 'rxjs/add/operator/timeoutWith';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { GlobalEvent, GlobalEventService } from './global-event.service';
import { environment } from 'src/environments/environment';
import { ReceiptRequest } from '../models/receipt-request.model';

@Injectable()
export class ReceiptsService {

  private _http: HttpClient;
  protected _orders: Array<Order>;
  protected _orderQuery: OrderQuery;
  protected _eventService: GlobalEventService;
  static readonly TIMEOUT = 'TIMEOUT';

  constructor(http: HttpClient, eventService: GlobalEventService) {
    this._http = http;
    this._eventService = eventService;
  }

  getOrders(): Array<Order> {
    if (this._orders) {
      return this._orders;
    }

    return [];
  }

  getOrderQuery(): OrderQuery {
    if (this._orderQuery) {
      return this._orderQuery;
    }

    return null;
  }

  lookup(receiptRequest: ReceiptRequest): Observable<ReceiptLookupResponse> {

    let startDate = `${receiptRequest.startDate.getFullYear()}-${receiptRequest.startDate.getMonth() + 1}-${receiptRequest.startDate.getDate()}`;
    let endDate = `${receiptRequest.endDate.getFullYear()}-${receiptRequest.endDate.getMonth() + 1}-${receiptRequest.endDate.getDate()}`;

    const options = {
      params: new HttpParams()
        .set('account', receiptRequest.account ? receiptRequest.account : '')
        .set('cardholderName', receiptRequest.cardHolderName ? receiptRequest.cardHolderName : '')
        .set('startDate', startDate)
        .set('endDate', endDate)
        .set('passengerFirstName', receiptRequest.passengerFirstName ? receiptRequest.passengerFirstName : '')
        .set('passengerLastName', receiptRequest.passengerLastName ? receiptRequest.passengerLastName : '')
        .set('originAirportCode', receiptRequest.originAirportCode ? receiptRequest.originAirportCode : '')
        .set('destAirportCode', receiptRequest.destAirportCode ? receiptRequest.destAirportCode : '')
    };

    return this._http.get<Order[]>('/1/guestServices/receipts/atlas/receipts/searchReceipts', options)
      .timeoutWith(environment.timeOut, defer(() => observableThrowError(new HttpErrorResponse({ statusText: ReceiptsService.TIMEOUT }))))
      .map(response => {
        this._orders = response;
        return {
          status: ReceiptLookupStatus.SUCCESS,
          orders: this.postProcess(response)
        };
      })
      .catch(err => {
        if (ReceiptsService.TIMEOUT === err.statusText) {
          this._eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return of({
            status: ReceiptLookupStatus.TIMEOUT,
            orders: []
          });
        }
        if (404 === err.status) {
          return of({
            status: ReceiptLookupStatus.DOES_NOT_EXIST,
            orders: []
          });
        }
        return of({
          status: ReceiptLookupStatus.SYSTEM_FAILURE,
          orders: []
        });
      });
  }

  searchByStripeSuffix(stripeSuffix: string): Observable<ReceiptLookupResponse> {
    const options = {
      params: new HttpParams()
        .set('paymentDescriptorSuffix', stripeSuffix)
    };
    return this._http.get<Order[]>('/1/guestServices/receipts/atlas/receipts/stripe_by_suffix', options)
      .map(response => {
        this._orders = response;

        return {
          status: ReceiptLookupStatus.SUCCESS,
          orders: this.postProcess(response)
        };
      })
      .catch(err => {
        if (ReceiptsService.TIMEOUT === err.statusText) {
          this._eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return of({
            status: ReceiptLookupStatus.TIMEOUT,
            orders: []
          });
        }
        if (404 === err.status) {
          return of({
            status: ReceiptLookupStatus.DOES_NOT_EXIST,
            orders: []
          });
        }
        return of({
          status: ReceiptLookupStatus.SYSTEM_FAILURE,
          orders: []
        });
      });
  }

  protected postProcess(orders: Array<Order>): Array<Order> {

    orders.sort((leftSide, rightSide) => {
      const leftTime: number = new Date(leftSide.AuthorizationTimeStamp).getTime();
      const rightTime: number = new Date(rightSide.AuthorizationTimeStamp).getTime();
      return leftTime > rightTime ? -1 : leftTime < rightTime ? 1 : 0;
    });

    return orders;
  }
}
