import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {GlobalEvent, GlobalEventService} from '../../services/global-event.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit, OnDestroy {

  showSpinner = false;
  showPanel = false;
  private inProgress = false;
  private _subscriptions: Subscription[];

  constructor(private _eventService: GlobalEventService) {
    this._subscriptions = [];
  }

  ngOnInit() {
    this._subscriptions.push(
      this._eventService.on(GlobalEvent.AJAX_START).subscribe(() => {
        this.showSpinner = true;
        this.inProgress = true;
        const thiz = this;
        setTimeout(function() { if (thiz.inProgress) { thiz.showPanel = true; } }, 1000);
      })
    );
    this._subscriptions.push(
      this._eventService.on(GlobalEvent.AJAX_END).subscribe(() => {
        this.inProgress = false;
        this.showSpinner = false;
        this.showPanel = false;
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach((subscription) => {subscription.unsubscribe(); });
  }
}
