<div id="spinner" *ngIf="showSpinner">
  <div class="modal-film">
    <div *ngIf="showPanel" class="spinner-panel">
      <img alt="Spinner" class="spinner-image" src='../../../../assets/clear-spinner.gif'>
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="500" height="330" viewBox="0 0 500 330">
        <defs>
          <rect id="a" width="500" height="330" rx="3"/>
        </defs>
        <g fill="none" fill-rule="evenodd">
          <rect width="500" height="330" fill="#01426A" rx="3"/>
          <use fill="#01426A" xlink:href="#a"/>
          <text fill="#FFF" font-family="Circular" font-size="16" font-weight="bold">
            <tspan x="158.464" y="171">Processing your request</tspan>
          </text>
          <g fill-rule="nonzero">
            <path fill="#B3D57D" d="M500 235.008c-21.304 33.827-52.477 67.298-94.194 95.707H500v-95.707z"/>
            <path fill="#48A9C5" d="M500 235.008v-50.794c-36.231 36.821-76.63 68.724-122.481 92.071l-.713.356-.962 1.32c-10.117 13.26-20.698 26.127-31.885 38.532-4.346 4.812-8.8 9.624-13.252 14.222h75.099c41.717-28.41 72.89-61.88 94.194-95.707z"/>
            <path fill="#2774AE" d="M376.806 276.535l.713-.357c45.85-23.24 86.25-55.143 122.481-91.964V74.321c-37.656 67.048-75.313 138.801-121.626 200.182l-1.568 2.032zM343.96 316.493c11.186-12.405 21.767-25.273 31.884-38.533l.962-1.319-8.906 4.35c-58.355 27.553-118.17 38.638-183.864 49.724h146.67c4.454-4.705 8.907-9.41 13.253-14.222z"/>
          </g>
          <g transform="translate(333.371 114.436)">
            <circle cx="2.263" cy="2.223" r="2.061" stroke="#FFF" stroke-width="2"/>
            <path fill="#FFF" fill-rule="nonzero" d="M1.332 3.589V.745h1.06c.61 0 .904.451.904.903 0 .451-.294.745-.61.903l.61 1.06-.61-.022-.513-1.038h-.367v1.038h-.474zm1.512-1.964c0-.293-.158-.451-.451-.451h-.587v.903h.587c.293 0 .451-.158.451-.452z"/>
          </g>
          <path fill="#FFF" fill-rule="nonzero" d="M324.236 109.662c-1.496 1.37-4.511 3.652-7.096 3.652-1.813-.16-1.813-1.666-.612-3.948 4.511-8.65 9.204-13.19 14.917-13.19h1.065l-8.274 13.486zm7.843-18.37c-10.7 0-17.342 6.23-23.055 20.036-1.496 3.492-2.266 7.736 2.72 7.736 2.857 0 7.527-1.506 10.86-3.948-.295 1.073-.454 2.739-.295 3.652h7.504c.294-2.876 1.36-6.687 3.921-11.068l9.34-15.792c-3.763-.296-7.367-.616-10.995-.616z"/>
          <path fill="#FFF" fill-rule="nonzero" d="M307.298 125.545h-2.423c-3.201 0-6.082-2.096-6.837-5.25l-3.795-14.081-6.997 11.693h-8.048l22.476-37.603c2.88-4.934 5.922-6.601 11.41-6.601h.754l-15.045 24.873 11.25-7.638h8.962l-18.84 12.888 7.133 21.719zM275.424 101.062l2.274 1.513c2.411 1.674 3.48 4.128 3.184 6.261-.91 5.642-4.98 10.228-13.759 10.228-3.775 0-5.594-.16-10.143-.917l3.48-5.802c3.184.62 4.98.917 7.414.917 3.32 0 4.685-1.513 4.844-2.752.159-.757-.296-1.513-1.82-2.752l-1.955-1.215c-2.866-2.133-3.64-3.807-3.32-6.399.75-5.022 6.504-8.852 14.509-8.852 2.57 0 5.299.16 8.323.459l-3.183 5.48c-2.016-.39-4.751-.531-7.414-.297-2.434.16-3.958.917-4.094 1.995-.16.757.16 1.215 1.66 2.133zM246.463 109.662c-1.495 1.37-4.508 3.652-7.091 3.652-1.813-.16-1.813-1.666-.612-3.948 4.509-8.65 9.198-13.19 14.907-13.19h1.065l-8.27 13.486zm7.839-18.37c-10.694 0-17.332 6.23-23.04 20.036-1.496 3.492-2.266 7.736 2.718 7.736 2.855 0 7.521-1.506 10.852-3.948-.295 1.073-.453 2.739-.295 3.652h7.522c.294-2.876 1.359-6.687 3.919-11.068l9.334-15.792c-3.784-.296-7.408-.616-11.01-.616zM248.649 72.777l-21.208 35.43c-2.278 3.626-3.486 7.39-3.782 10.857h-8.337c.615-4.51 2.278-8.75 5.9-14.915l14.989-24.708c3.03-4.964 5.9-6.641 11.367-6.641l1.07-.023zM233.552 70c-7.585 0-11.537 1.934-18.984 7.33l-25.22 17.942h-16.243c-4.706 0-8.36.158-11.4 2.99l-4.111 3.733s12.153-.45 23.553-.9L157 118.14h10.486l24.763-17.493c4.866-.157 8.041-.292 8.201-.292 5.78-.158 8.955-5.082 8.955-5.082h-9.412l19.898-14.21-15.032 23.631c-3.198 5.374-4.706 9.264-5.163 13.446h8.498c.297-3.598 1.508-7.173 3.792-10.77l20.195-33.187c1.37-2.248 2.581-4.182 2.581-4.182h-1.21z"/>
        </g>
      </svg>
    </div>
  </div>
</div>
